<template>
  <div class="sucess" v-if="isShow">
    <div class="top-content">
      <div class="icon"><img src="../../assets/couponsucsss.png" alt="" /></div>
      <div style="font-size: 25px; font-weight: 400">领取成功</div>
    </div>
    <div v-if="isfollow" class="center-text" style="width: 265px">
      点击下方按钮跳转小程序查看优惠券
    </div>
    <div v-else class="center-text" style="width: 285px">
      关注公众号享受更多车主优惠
    </div>
    <button v-if="!isfollow" class="bottom-btn" @click="handleJump(isfollow)">
      {{ isfollow ? "我的优惠券" : "去关注" }}
    </button>
    <div v-else>
      <wx-open-launch-weapp
        id="launch-btn"
        :username="appletId"
        path="packageA/pages/discountCoupon/discountCoupon"
      >
        <script type="text/wxtag-template">
          <style>.btn {box-sizing: border-box;border: none;width: 345px;height: 44px;background-color: rgb(35, 112, 255);border-radius: 22px;font-size: 15px;text-align: center;font-weight: 400;color: #ffffff; }</style>
          <button class="btn">我的优惠券</button>
        </script>
      </wx-open-launch-weapp>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import preventBack from "vue-prevent-browser-back"; //组件内单独引入

export default {
  name: "Couponsucess",
  mixins: [preventBack],
  data() {
    return {
      domain: process.env.VUE_APP_TEST_DOMAIN,
      isfollow: false,
      isShow: false,
      unionBase64: null,
      isPlayShow: null,
      btn: null,
      appletId: null,
    };
  },
  async mounted() {
    let appId = localStorage.getItem("appId");
    let openId = localStorage.getItem("openId");
    let itemId = localStorage.getItem("itemId");
    await this.$axios
      .post(`${this.domain}/api/pay/followApp`, {
        appId,
        openId,
        itemId,
      })
      .then((res) => {
        this.unionBase64 = res.data.unionBase64;
        this.appletId = res.data.ext2;
        if (res.data.code == 200) {
          this.isfollow = true;
          this.isShow = true;
        } else {
          this.isfollow = false;
          this.isShow = true;
        }
      });
    let url = decodeURIComponent(localStorage.getItem("firstUrl"));
    await this.$axios
      .post(`${this.domain}/api/pay/getWxAuth`, {
        appId,
        url,
        itemId,
      })
      .then((res) => {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
          appId: res.data.data.appId, // 必填，公众号的唯一标识
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.noncestr, // 必填，填任意非空字符串即可
          signature: res.data.data.signature, // 必填，填任意非空字符串即可
          jsApiList: ["showOptionMenu", "chooseImage", "previewImage"], // 必填，随意一个接口即可
          openTagList: ["wx-open-launch-weapp"], // 填入打开小程序的开放标签名
        });
        // 通过ready接口处理成功验证
        wx.ready(function () {
          console.log("ready");
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
        });
        // 通过error接口处理失败验证
        wx.error(function (res) {
          console.log(res);
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
        });
      });
  },
  methods: {
    async handleJump(val) {
      if (val) {
        console.log(1);
      } else {
        let { unionBase64 } = this;
        let url = `https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=${unionBase64}&scene=110#wechat_redirect`;
        // window.open(url, "_selft");
        location.href = url;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sucess {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 15px;

  .top-content {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      margin-right: 5px;
      width: 50px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .center-text {
    margin: 25px auto;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    color: #a3a3a3;
  }

  .bottom-btn {
    box-sizing: border-box;
    border: none;
    width: 345px;
    height: 44px;
    background-color: rgb(35, 112, 255);
    border-radius: 22px;
    font-size: 15px;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
  }

  ::v-deep .el-button {
    line-height: normal;
  }
}
</style>
